<template>
	<div class="footer-content">
		<div class="footer">
			<div class="navigate-list">
				<div class="list-item">
					<p class="main-title">国际物流</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/serviceType/overseas')">境外服务</p>
							<p @click="$router.push('/serviceType/customs')">报关报检服务</p>
							<p @click="$router.push('/serviceType/ground')">地接及单证服务</p>
							<p @click="$router.push('/serviceType/cross')">电商运营</p>
						</div>
						<div class="right">
							<p @click="$router.push('/serviceType/lease')">集装箱租赁</p>
							<p @click="$router.push('/serviceType/warehousing')">保税仓储</p>
							<p @click="$router.push('/serviceType/trailer')">配送服务</p>
							<p @click="$router.push('/serviceType/finance')">金融服务</p>
						</div>
					</div>
				</div>
				<div class="list-item">
					<p class="main-title">会议会展</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/service/display')">展会服务</p>
							<p @click="$router.push('/service/conference')">会务服务</p>
							<p @click="$router.push('/service/translation')">翻译服务</p>
							<p @click="$router.push('/service/marketing')">营销推广</p>
						</div>
					</div>
				</div>
				<div class="list-item">
					<p class="main-title">数字科技</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/mainService/exhibition')">智能数据平台</p>
							<p @click="$router.push('/mainService/synthesis')">数据综合管控</p>
							<p @click="$router.push('/mainService/asset')">数字资产运营</p>
							<p @click="$router.push('/mainService/medical')">智慧医疗服务</p>
						</div>
					</div>
				</div>
				<div class="list-item">
					<p class="main-title">服务案例</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/serviceCase')">客户案例</p>
						</div>
					</div>
				</div>
				<div class="list-item">
					<p class="main-title">新闻公告</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/NewsCenter')">平台公告</p>
							<p @click="$router.push('/NewsCenter')">行业新闻</p>
						</div>
					</div>
				</div>
				<div class="list-item">
					<p class="main-title">关于我们</p>
					<p style="display: flex; align-items: center; margin-top: 15px">
						<span class="border-line"></span><span class="line"></span>
					</p>
					<div class="item-detail">
						<div class="left">
							<p @click="$router.push('/AboutUs')">关于我们</p>
							<p @click="$router.push('/AboutUs')">平台优势</p>
							<p @click="$router.push('/AboutUs')">合作流程</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-detail">
			<p class="copyright-info">©2023 鸿森数字科技(西安)有限公司 www.hongsun-group.com</p>
			<p class="record">
				<a rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2022013813号-1</a>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		watch: {},
		methods: {},
	};
</script>
<style scoped lang="scss">
	.footer-content {
		width: 100%;
		background: #222e3c;
		.footer {
			width: 85.4%;
			margin: auto;
			height: auto;
			margin-bottom: 80px;
			// min-height: 399px;
			.navigate-list {
				min-width: 228px;

				display: flex;
				justify-content: space-between;
				.list-item {
					margin-top: 80px;
					cursor: pointer;
					.main-title {
						font-size: 20px;
						font-weight: 500;
						color: #ffffff;
						line-height: 28px;
					}
					.border-line {
						display: inline-block;
						width: 30px;
						height: 3px;
						background: #c02128;
					}
					.line {
						display: inline-block;
						width: 110px;
						height: 1px;
						background: #ffffff;
						opacity: 0.8;
					}
					.item-detail {
						// min-height: 264px;
						margin-top: 20px;
						display: flex;
						justify-content: flex-start;
						.left {
							& > p {
								opacity: 0.6;
								font-size: 14px;
								font-weight: 400;
								color: #ffffff;
								line-height: 22px;
								&:not(:first-child) {
									margin-top: 10px;
								}
							}
						}
						.right {
							& > p {
								opacity: 0.6;
								font-size: 14px;
								font-weight: 400;
								color: #ffffff;
								line-height: 22px;
								&:not(:first-child) {
									text-align: right;
									margin-top: 10px;
								}
							}
						}
					}
				}
			}
		}
		.bottom-detail {
			width: 100%;
			height: auto;
			text-align: center;
			min-height: 114px;
			box-sizing: border-box;
			background: #1d2937;
			.copyright-info {
				padding-top: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				line-height: 28px;
				opacity: 0.6;
			}
			.record {
				// margin-top: 15px;
				font-size: 14px;
				font-weight: 400;
				a {
					color: #ffffff;
					text-decoration: none;
					font-size: 14px;
					font-weight: 400;
					opacity: 0.6;
				}
				color: #ffffff;
				line-height: 28px;
			}
		}
	}
</style>

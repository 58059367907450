<template>
	<div class="nav-container">
		<div class="menu-content2">
			<div class="logo" @click="goIndex">
				<img src="../../assets/icons/logo.png" alt="" />
			</div>
			<div class="navbtn"></div>
		</div>
		<div class="menu-content">
			<div class="logo" @click="goIndex">
				<img src="../../assets/icons/logo.png" alt="" />
			</div>
			<div class="menu">
				<el-menu
					:default-active="$route.path"
					class="el-menu-demo"
					mode="horizontal"
					@select="handleSelect"
					background-color="#FFF"
					text-color="#222E3C"
					active-text-color="#C02028"
					router>
					<div v-for="item in navList" :key="item.id">
						<el-menu-item
							:class="navIndex == item.id ? 'navContent navContentAct' : 'navContent'"
							:index="item.id"
							v-if="item.children.length == 0">
							<span @click.stop="handleSelect4(item)">{{ item.title }}</span>
						</el-menu-item>
						<el-submenu
							:class="$route.path == item.id ? 'navContent navContentAct' : 'navContent'"
							:index="item.id"
							v-if="item.children.length != 0">
							<template slot="title">
								<span @click.stop="handleSelect2(item)">{{ item.title }}</span>
							</template>
							<div v-for="(val, ind) in item.children" :key="ind">
								<el-menu-item :index="val.id" @click="handleSelect3(val)" v-if="!val.children">{{
									val.title
								}}</el-menu-item>
								<!-- <P>{{ val.children }}</P> -->
								<div v-else>
									<el-submenu :index="val.id">
										<template slot="title"
											><span>{{ val.title }}</span></template
										>
										<div v-for="(i, index) in val.children" :key="index">
											<el-menu-item :index="i.id" @click="handleSelect5(i)">{{ i.title }}</el-menu-item>
										</div>
									</el-submenu>
								</div>
							</div>
						</el-submenu>
					</div>
				</el-menu>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navIndex: "1",
				navList: [
					{ title: "首页", id: "/home", children: [] },
					{
						title: "国际物流",
						id: "/National",
						point: "section1",
						children: [
							{ title: "境外服务", id: "/serviceType/overseas" },
							{ title: "报关报检服务", id: "/serviceType/customs" },
							{ title: "地接及单证服务", id: "/serviceType/ground" },
							{ title: "跨境电商服务", id: "/serviceType/cross" },
							{ title: "集装箱租赁", id: "/serviceType/lease" },
							{ title: "保税仓储", id: "/serviceType/warehousing" },
							{ title: "拖车服务", id: "/serviceType/trailer" },
							{ title: "金融服务", id: "/serviceType/finance" },
							// { title: "主营业务", id: "/National", point: "section1" },
							// {
							//   title: "弧形路线图",
							//   id: "/National#section2",
							//   point: "section2",
							// },
							// { title: "热门路线", id: "/National#section3", point: "section3" },
							// {
							//   title: "配套服务",
							//   id: "/National#section4",
							//   point: "section4",
							//   children: [
							//     { title: "境外服务", id: "/serviceType/overseas" },
							//     { title: "报关报检服务", id: "/serviceType/customs" },
							//     { title: "地接及单证服务", id: "/serviceType/ground" },
							//     { title: "跨境电商服务", id: "/serviceType/cross" },
							//     { title: "集装箱租赁", id: "/serviceType/lease" },
							//     { title: "保税仓储", id: "/serviceType/warehousing" },
							//     { title: "拖车服务", id: "/serviceType/trailer" },
							//     { title: "金融服务", id: "/serviceType/finance" },
							//   ],
							// },
							// { title: "班列特色", id: "/National#section5", point: "section5" },
							// { title: "合作伙伴", id: "/National#section6", point: "section6" },
						],
					},
					{
						title: "会议会展",
						id: "/Meeting",
						point: "section2",
						children: [
							{ title: "展会服务", id: "/service/display" },
							{ title: "会务服务", id: "/service/conference" },
							{ title: "翻译服务", id: "/service/translation" },
							{ title: "营销推广", id: "/service/marketing" },
							// { title: "业务特色", id: "/Meeting", point: "section2" },
							// {
							//   title: "主营业务",
							//   point: "section1",
							//   id: "/Meeting#section1",
							//   children: [
							//     { title: "展会服务", id: "/service/display" },
							//     { title: "会务服务", id: "/service/conference" },
							//     { title: "翻译服务", id: "/service/translation" },
							//     { title: "营销推广", id: "/service/marketing" },
							//   ],
							// },
							// { title: "服务案例", id: "/Meeting#section3", point: "section3" },
							// { title: "合作伙伴", id: "/Meeting#section4", point: "section4" },
						],
					},
					{
						title: "数字科技",
						id: "/DigitalTechnology",
						point: "section2",
						children: [
							{ title: "智能数据平台", id: "/mainService/exhibition" },
							{ title: "数据综合管控", id: "/mainService/synthesis" },
							{ title: "数字资产运营", id: "/mainService/asset" },
							{ title: "智慧医疗服务", id: "/mainService/medical" },
							// { title: "业务特色", id: "/DigitalTechnology", point: "section2" },
							// {
							//   title: "主营业务",
							//   id: "/DigitalTechnology#section1",
							//   point: "section1",
							//   children: [
							//     { title: "智能数据平台", id: "/mainService/exhibition" },
							//     { title: "数据综合管控", id: "/mainService/synthesis" },
							//     { title: "数字资产运营", id: "/mainService/asset" },
							//     { title: "智慧医疗服务", id: "/mainService/medical" },
							//   ],
							// },
							// {
							//   title: "服务案例",
							//   id: "/DigitalTechnology#section3",
							//   point: "section3",
							// },
							// {
							//   title: "合作伙伴",
							//   id: "/DigitalTechnology#section4",
							//   point: "section4",
							// },
						],
					},
					{
						title: "服务案例",
						id: "/serviceCase",
						children: [],
					},
					{ title: "新闻公告", id: "/NewsCenter", children: [] },
					{ title: "关于我们", id: "/AboutUs", children: [] },
				],
			};
		},
		watch: {},
		computed: {},
		created() {
			// this.getdata();
		},
		methods: {
			getdata() {
				this.$axios
					.get("menu/getList", { pid: 1 })
					.then(res => {
						console.log(res);
						this.navList[1].children = res.data;
						console.log(this.navList);
					})
					.catch(error => {
						console.log(error, "请求失败");
					});
				this.$axios
					.get("menu/getList", { pid: 10 })
					.then(res => {
						this.descdata = res.data.content;
					})
					.catch(error => {
						console.log(error, "请求失败");
					});
				this.$axios
					.get("menu/getList", { pid: 15 })
					.then(res => {
						this.descdata = res.data.content;
					})
					.catch(error => {
						console.log(error, "请求失败");
					});
			},
			handleSelect(index) {
				this.navIndex = index;
				console.log(index, "00000000000000");
			},
			// 点击有二级菜单的一级菜单
			handleSelect2(item) {
				console.log(item, "aaaaaaaaa");
				// return
				this.navIndex = item.children[0].id;
				if (item.id == "/National") {
					localStorage.setItem("selectid", 1);
					this.$emit("id", 1);
					this.$router.push("/National");
				} else if (item.id == "/Meeting") {
					this.$emit("id", 1);
					localStorage.setItem("selectid", 1);
					this.$router.push("/Meeting");
				} else if (item.id == "/DigitalTechnology") {
					this.$emit("id", 1);
					localStorage.setItem("selectid", 1);
					this.$router.push("/DigitalTechnology");
				}
			},
			// 点击二级菜单
			handleSelect3(item) {
				console.log(item.id, "bbbbbbbbb");
				this.navIndex = item.id;
				if (item.id == "/National") {
					console.log("item.point21", item.point);
					localStorage.setItem("selectid", item.point);
					this.$emit("id", item.point);
					this.$router.push("/National");
				} else if (item.id == "/National#section2") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/National");
				} else if (item.id == "/National#section3") {
					console.log("iiiii3343434");
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/National");
				} else if (item.id == "/National#section4") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/National");
				} else if (item.id == "/National#section5") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/National");
				} else if (item.id == "/National#section5") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/National");
				}
				if (item.id == "/Meeting") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/Meeting");
				} else if (item.id == "/Meeting#section1") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/Meeting");
				} else if (item.id == "/Meeting#section3") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/Meeting");
				} else if (item.id == "/Meeting#section4") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/Meeting");
				}
				if (item.id == "/DigitalTechnology") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/DigitalTechnology");
				} else if (item.id == "/DigitalTechnology#section1") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/DigitalTechnology");
				} else if (item.id == "/DigitalTechnology#section3") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/DigitalTechnology");
				} else if (item.id == "/DigitalTechnology#section4") {
					this.$emit("id", item.point);
					localStorage.setItem("selectid", item.point);
					this.$router.push("/DigitalTechnology");
				}
			},
			// 点击没有二级菜单的一级菜单
			handleSelect4(item) {
				console.log(item, "cccccccccccc");
				this.navIndex = item.id;
				console.log("item.id", item.id, item.id == "/NewsCenter");
				let that = this;
				if (item.id == "/home") {
					this.$router.push("/home");
					that.navIndex = item.id;
				} else if (item.id == "/serviceCase") {
					this.$router.push("/serviceCase");
					that.navIndex = item.id;
				} else if (item.id == "/NewsCenter") {
					console.log("uuu");
					this.$router.push("/NewsCenter");
					that.navIndex = item.id;
				} else if (item.id == "/AboutUs") {
					this.$router.push("/AboutUs");
					that.navIndex = item.id;
				}
			},
			handleSelect5(item) {
				this.navIndex = item.id;

				this.$emit("id", item.id);
				localStorage.setItem("id", item.id);
				if (item.id == "/serviceType/overseas") {
					this.$emit("id", "/serviceType/overseas");
					this.$router.push("/serviceType/overseas");
				} else if (item.id == "/serviceType/customs") {
					this.$emit("id", "/serviceType/customs");
					this.$router.push("/serviceType/customs");
				} else if (item.id == "/serviceType/ground") {
					this.$emit("id", "/serviceType/ground");
					this.$router.push("/serviceType/ground");
				} else if (item.id == "/serviceType/cross") {
					this.$emit("id", "/serviceType/cross");
					this.$router.push("/serviceType/cross");
				} else if (item.id == "/serviceType/lease") {
					this.$emit("id", "/serviceType/lease");
					this.$router.push("/serviceType/lease");
				} else if (item.id == "/serviceType/warehousing") {
					this.$emit("id", "/serviceType/warehousing");
					this.$router.push("/serviceType/warehousing");
				} else if (item.id == "/serviceType/trailer") {
					this.$emit("id", "/serviceType/trailer");
					this.$router.push("/serviceType/trailer");
				} else if (item.id == "/serviceType/finance") {
					this.$emit("id", "/serviceType/finance");
					this.$router.push("/serviceType/finance");
				}
				if (item.id == "/service/display") {
					this.$emit("id", "/service/display");
					this.$router.push("/service/display");
				} else if (item.id == "/service/conference") {
					this.$emit("id", "/service/conference");
					this.$router.push("/service/conference");
				} else if (item.id == "/service/translation") {
					this.$emit("id", "/service/translation");
					this.$router.push("/service/translation");
				} else if (item.id == "/service/marketing") {
					this.$emit("id", "/service/marketing");
					this.$router.push("/service/marketing");
				}
				if (item.id == "/mainService/exhibition") {
					this.$emit("id", "/mainService/exhibition");
					this.$router.push("/mainService/exhibition");
				} else if (item.id == "/mainService/synthesis") {
					this.$emit("id", "/mainService/synthesis");
					this.$router.push("/mainService/synthesis");
				} else if (item.id == "/mainService/asset") {
					this.$emit("id", "/mainService/asset");
					this.$router.push("/mainService/asset");
				} else if (item.id == "/mainService/medical") {
					this.$emit("id", "/mainService/medical");
					this.$router.push("/mainService/medical");
				}
			},
			goIndex() {
				this.$router.push("/home");
			},
		},
	};
</script>
<style scoped lang="scss">
	@media (max-width: 480px) {
		.menu-content {
			display: none !important;
		}
		.menu-content2 {
			display: block !important;
			width: 85.4%;
			// height: 120px;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo {
				img {
					// display: inline-block;
					width: 110px;
					display: block;
					margin: 17px 0;
					// height: 68px;
				}
			}
		}
	}
	.menu-content2 {
		display: none;
	}
	.el-row {
		margin-bottom: 20px;
	}
	.el-row:last-child {
		margin-bottom: 0;
	}
	.el-col {
		border-radius: 4px;
	}
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
	.nav-container {
		z-index: 11;
		width: 100%;
		// height: 120px;
		padding: 5px 0;
		opacity: 0.9;
		background-color: #fff;
		.menu-content {
			width: 85.4%;
			// height: 120px;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo {
				img {
					// display: inline-block;
					width: 158px;
					display: block;
					// height: 68px;
				}
			}

			.menu .el-menu {
				height: 63px;
				display: flex;
			}
			.menu {
				margin-right: 116px;
				display: flex;
				// /deep/ .el-menu-item.is-active {
				//   background-color: #fff !important;
				//   color: #fff;

				//   span {
				//     color: #fff !important;
				//   }
				// }
				.el-menu.el-menu--horizontal {
					border-bottom: none;
				}
			}
			/deep/ .menu .el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
				height: 63px;
				box-sizing: border-box;
			}
			/deep/ .menu .el-menu--horizontal > .el-sub-menu .el-sub-menu__title span {
				color: #000;
				border-bottom: 5px solid transparent;
				height: 63px;
				line-height: 63px;
				font-size: 22px;
				padding: 0;
				display: flex;
				align-items: center;
				margin-top: 4px;
				box-sizing: border-box;
			}
			/deep/ .menu .el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover span {
				color: #0079c3;
				border-bottom: 5px solid #0079c3;
				height: 61px;
				line-height: 61px;
				font-size: 22px;
				padding: 0;
				display: flex;
				align-items: center;
				margin-top: 4px;
				box-sizing: border-box;
			}
			/deep/ .menu .el-menu--horizontal > .el-sub-menu .el-sub-menu__title .el-icon {
				display: none !important;
			}
			/deep/ .menu .el-menu--horizontal > .is-active .el-sub-menu__title {
				border-color: transparent !important;
			}
			/deep/ .menu .el-menu--horizontal > .is-active > .el-sub-menu__title > span {
				color: #c02028;
				border-bottom: 5px solid #c02028;
				height: 61px;
				line-height: 61px;
				font-size: 22px;
				padding: 0;
				display: flex;
				align-items: center;
				margin-top: 4px;
				box-sizing: border-box;
			}
			/deep/ .menu .el-menu--horizontal > .el-sub-menu .el-menu .el-menu-item {
				color: red;
			}
			/deep/ .el-popper .el-menu--popup-container {
				border-bottom: none !important;
			}
			/deep/ .el-popper .el-menu--popup-container .el-menu--popup {
				border-bottom-left-radius: 3px !important;
				border-bottom-right-radius: 3px !important;
				overflow: hidden;
				min-width: auto;
				padding: 12px 0;
			}
			/deep/ .el-popper .el-menu--popup-container .el-menu--popup .el-menu-item {
				color: #222222 !important;
				background: transparent;
				font-size: 20px;
				padding: 20px;
				line-height: 34px !important;
				height: 34px !important;
				text-align: center;
				display: flex;
				justify-content: center;
			}
			/deep/ .el-popper .el-menu--popup-container .el-menu--popup .el-menu-item:hover {
				color: #c02028 !important;
				background: transparent;
			}
			/deep/ .el-popper .el-menu--popup-container .el-menu--popup .is-active {
				color: #c02028 !important;
				background: transparent;
			}
			/* body .el-popper:nth-child(2) {left: 770px!important;} */
			body .el-popper:nth-child(3) .el-menu--popup {
				min-width: 170px;
			}
			/* .nav .el-menu--horizontal {} */
		}
	}
	.navContentAct span {
		color: #c02028 !important;
	}
</style>

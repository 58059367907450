import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
/**解决点击以打开路由出错开始 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};
const routes = [
	{
		path: "/",
		// 重新定向
		redirect: "/home",
		meta: {
			title: "鸿森国际",
		},
	},
	{
		path: "/home",
		name: "Home",
		component: () => import("../views/home/index.vue"),
		meta: {
			title: "鸿森国际",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},

	{
		path: "/national",
		name: "National",
		component: () => import("../views/national/national.vue"),
		meta: {
			title: "国际物流",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/national",
		name: "AncillaryServices",
		component: () => import("../views/national/ancillaryServices.vue"),
		meta: {
			title: "国际物流",
			requiresAuth: false,
			isKeepAlive: false,
		},
		children: [
			{
				path: "/serviceType/overseas",
				name: "Overseas",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "境外服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/customs",
				name: "Customs",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "报关报检服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/ground",
				name: "Ground",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "地接及单证服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/cross",
				name: "Cross",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "跨境电商服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/lease",
				name: "Lease",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "集装箱租赁",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/warehousing",
				name: "Warehousing",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "保税仓储",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/trailer",
				name: "Trailer",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "拖车服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/serviceType/finance",
				name: "Finance",
				component: () => import("../views/national/details.vue"),
				meta: {
					title: "金融服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
		],
	},
	{
		path: "/meeting",
		name: "Meeting",
		component: () => import("../views/meeting/index.vue"),
		meta: {
			title: "会议会展",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/meeting",
		name: "MainBusiness",
		component: () => import("../views/meeting/mainBusiness.vue"),
		meta: {
			title: "会议会展",
			requiresAuth: false,
			isKeepAlive: false,
		},
		children: [
			{
				path: "/service/display",
				name: "Display",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "展会服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/service/conference",
				name: "Conference",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "会务服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/service/translation",
				name: "Translation",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "翻译服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/service/marketing",
				name: "Marketing",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "营销推广",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
		],
	},
	{
		path: "/meeting/case",
		name: "Case",
		component: () => import("../views/meeting/case/index.vue"),
		meta: {
			title: "会议会展-服务案例",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/digitalTechnology",
		name: "DigitalTechnology",
		component: () => import("../views/digitalTechnology/index.vue"),
		meta: {
			title: "数字科技",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/digitalTechnology",
		name: "TechnologyService",
		component: () => import("../views/digitalTechnology/technologyService.vue"),
		meta: {
			title: "数字科技",
			requiresAuth: false,
			isKeepAlive: false,
		},
		children: [
			{
				path: "/mainService/exhibition",
				name: "Exhibition",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "智能数据平台",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/mainService/synthesis",
				name: "Synthesis",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "数据综合管控",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/mainService/asset",
				name: "Asset",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "数字资产运营",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
			{
				path: "/mainService/medical",
				name: "Medical",
				component: () => import("@/views/national/details.vue"),
				meta: {
					title: "智慧医疗服务",
					requiresAuth: false,
					isKeepAlive: false,
				},
			},
		],
	},
	{
		path: "/serviceCase",
		name: "ServiceCase",
		component: () => import("../views/serviceCase/index.vue"),
		meta: {
			title: "服务案例",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/newsCenter",
		name: "NewsCenter",
		component: () => import("../views/newsCenter/index.vue"),
		meta: {
			title: "新闻公告",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/aboutUs",
		name: "AboutUs",
		component: () => import("../views/aboutUs/index.vue"),
		meta: {
			title: "关于我们",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("../views/register/index.vue"),
		meta: {
			title: "注册",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
	{
		path: "/serviceDetail",
		name: "serviceDetail",
		component: () => import("../views/serviceCase/detail.vue"),
		meta: {
			title: "案例详情",
			requiresAuth: false,
			isKeepAlive: false,
		},
	},
];

const router = new VueRouter({
	// history 可以让路径不显示# hash模式路径会有#
	// history 模式需要在后端代理服务器的时候进行对应的配置
	mode: "hash",
	base: "https://www.hongsun-group.com",
	routes,
	// 灵活的改变点击后的颜色变化
	linkActiveClass: "active",
});
// 从
router.beforeEach((to, from, next) => {
	// document.title = to.meta.title;

	console.log("to---------->", to);
	if (to.meta.title || to.matched[0]) {
		document.title = to.matched[0].meta.title || to.meta.title;
	}
	window.scrollTo(0, 0);
	next();
});
export default router;

<template>
  <div id="app">
    <!-- <keep-alive>
      <router-view></router-view>
    </keep-alive> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./components/head/head.vue";
import Nav from "./components/nav/nav.vue";
import Footer from "./components/footer/footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Nav,
    Footer,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>

import Vue from "vue";

import router from "./router";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import store from "./store";
import "@/assets/iconfont/iconfont.css";
//把axios实例化后引入main.js文件。挂载在axios原型链上全局使用。
import http from "./utils/api";
import $ from "jquery";
import tool from "./utils/tools";
Vue.use(tool);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = http;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
